.product{
    width: 300px;
    height: 510px;
    border: 1px solid #95A0A7;
    border-radius: 8px;
    position: relative;
}
.product img{
    width: 286px;
    height: 286px;
    border-radius: 8px;
    margin: 7px;
}

.product-info{
    margin: 0 14px;
}

.product-info p{
    margin-top: 5px;
    margin-bottom: 0;
}

.product-name{
    font-weight: 400;
    font-size: 21px;
    line-height: 25px;
    margin-top: 7px;
}

.btn-cart{
    width: 100%;
    background-color: #FFE0B3;
    border: none;
    border-radius: 0 0 8px 8px;
    position: absolute;
    bottom: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
}

.btn-cart:hover{
    background-color: orange;
}
